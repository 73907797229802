import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';

import { useHistory } from 'react-router-dom';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    sectionName,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const history = useHistory();
  const button2 = sectionName.includes('button2') ? true : false;
  const salto = description?.content.includes(
    '1Mi Perfil + 2Validar experiencia + 3Configurar cuenta'
  )
    ? true
    : false;

  const video = sectionName.includes('video') ? true : sectionName.includes('Video') ? true : false;

  let urlVideo = '';

  if (video) {
    console.log('video', video);
    let texto = sectionName;
    console.log('texto', texto);
    let regex = /\[([^\]]+)\]/;
    let resultado = texto.match(regex);
    console.log('resultado', resultado);
    if (resultado) {
      console.log('Valor dentro de los corchetes: ' + resultado[1]);
      urlVideo = 'https://drive.google.com/file/d/' + resultado[1] + '/preview';
    } else {
      console.log('No se encontraron corchetes.');
    }
  }
  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {video ? (
        <div className={css.videoContainer}>
          <div className={css.textContainerVideo}>
            <Field className={css.titleSection} data={title} options={fieldOptions} />
            <Field className={css.titleText} data={description} options={fieldOptions} />
            <Field data={callToAction} className={css.buttonVideo} options={fieldOptions} />
          </div>
          <div className={css.iframeContainer}>
            <iframe src={urlVideo} allow="autoplay" className={css.video}></iframe>
          </div>
        </div>
      ) : hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            {salto ? (
              <div className={css.textContainer}>
                <div className={css.firstParagraph}>
                  1 <br />
                  Mi Perfil
                </div>
                <div className={css.firstParagraph}>+</div>
                <div className={css.textValidar}>
                  2 <br />
                  Validar experiencia
                </div>
                <div className={css.firstParagraph}>+</div>
                <div className={css.firstParagraph}>
                  3 <br />
                  Configurar cuenta
                </div>
              </div>
            ) : (
              <Field
                data={description}
                className={defaultClasses.description}
                options={fieldOptions}
              />
            )}
            {salto ? (
              <div className={css.textValidar2}>
                Si tienes suficiente experiencia podrás ofrecer tus servicios profesionales
              </div>
            ) : null}

            {button2 ? (
              <div className={css.button2Container}>
                <button className={css.button2} onClick={() => history.push(callToAction?.href)}>
                  {callToAction?.content}
                </button>
              </div>
            ) : (
              <Field
                data={callToAction}
                className={defaultClasses.ctaButton}
                options={fieldOptions}
              />
            )}
          </>
        </header>
      ) : null}
      {/*{hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          {video ? (
            <div style={{ display: 'inline-flex', flexDirection: 'row', width: '100%' }}>
              <div style={{ width: '50%' }}>
                <Field data={title} options={fieldOptions} />
                <Field data={description} options={fieldOptions} />
                <Field
                  data={callToAction}
                  className={defaultClasses.ctaButton}
                  options={fieldOptions}
                />
              </div>
              <div style={{ width: '50%' }}>
                <iframe
                  src="https://drive.google.com/file/d/1-W8XVA7_8U4NPSIcSiVzv8e_1-TagR6O/preview"
                  width="828"
                  height="493"
                  allow="autoplay"
                  style={{ border: 'none' }}
                ></iframe>
              </div>
            </div>
          ) : (
            <>
              <Field data={title} className={defaultClasses.title} options={fieldOptions} />
              {salto ? (
                <div className={css.textContainer}>
                  <div className={css.firstParagraph}>
                    1 <br />
                    Mi Perfil
                  </div>
                  <div className={css.firstParagraph}>+</div>
                  <div
                    style={{
                      textAlign: 'center',
                      color: '#F4F4F4',
                      fontSize: 24,
                      fontWeight: '500',
                      letterSpacing: 0.24,
                      wordWrap: 'break-word',
                    }}
                  >
                    2 <br />
                    Validar experiencia
                  </div>
                  <div className={css.firstParagraph}>+</div>
                  <div className={css.firstParagraph}>
                    3 <br />
                    Configurar cuenta
                  </div>
                </div>
              ) : (
                <Field
                  data={description}
                  className={defaultClasses.description}
                  options={fieldOptions}
                />
              )}
              {salto ? (
                <div
                  style={{
                    textAlign: 'center',
                    color: '#F4F4F4',
                    fontSize: 16,
                    fontWeight: '400',
                    letterSpacing: 0.16,
                    wordWrap: 'break-word',
                    marginTop: '32px',
                  }}
                >
                  Si tienes suficiente experiencia podrás ofrecer tus servicios profesionales
                </div>
              ) : null}

              {button2 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '32px',
                  }}
                >
                  <button className={css.button2} onClick={() => history.push(callToAction?.href)}>
                    {callToAction?.content}
                  </button>
                </div>
              ) : (
                <Field
                  data={callToAction}
                  className={defaultClasses.ctaButton}
                  options={fieldOptions}
                />
              )}
            </>
          )}
        </header>
      ) : null}
      */}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
